import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { PropTypes } from "prop-types";
import { filterHtml } from "../utils/filterHtml";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { Image } from "./Slices/Image";

export const EventTeasers = ({
  title,
  time = "coming",
  type = "All",
  display = "small",
  showPodcast = false,
}) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const event_teasers_title =
    title || t("Welke community activiteiten kan je verwachten?");

  const queryData = useStaticQuery(graphql`
    query EventTeasersQuery {
      allPrismicEvent(sort: { fields: data___date, order: ASC }) {
        nodes {
          _previewable
          data {
            date
            location
            teaser_title {
              text
            }
            teaser_abstract {
              html
            }
            title {
              text
            }
            type
            image {
              url
              alt
              localFile {
                publicURL
              }
            }
          }
          id
          url
          type
        }
      }
      prismicLevel2(
        data: { types: { elemMatch: { tag_type: { eq: "Audio" } } } }
      ) {
        id
        url
        type
        _previewable
        data {
          abstract {
            html
          }
          title {
            text
          }
          types {
            tag_type
          }
        }
      }
    }
  `);
  const { data } = useMergePrismicPreviewData(queryData);

  console.log(data);

  const currentDate = new Date();

  const filteredEvents = data?.allPrismicEvent.nodes
    .filter(({ data }) => {
      return (
        time === "All" ||
        (time === "Coming" && data.date >= currentDate.toISOString()) ||
        (time === "Past" && data.date < currentDate.toISOString())
      );
    })
    .filter(({ data }) => {
      return (
        type === "All" ||
        (type === "Events" && data.type === "Event") ||
        (type === "Webinars" && data.type === "Webinar")
      );
    })
    .sort((first, second) => {
      if (time === "Coming")
        return new Date(first.data.date) - new Date(second.data.date);
      else return new Date(second.data.date) - new Date(first.data.date);
    });

  return (
    <section className="region region-event-teasers">
      <div className="o-wrapper">
        <div className="o-1-col">
          <h2>{event_teasers_title}</h2>
        </div>

        {filteredEvents.length > 0 ? (
          <div className="o-3-col u-mt3">
            {filteredEvents.map((event) => (
              <div className="c-event-teaser__wrapper" key={event.id}>
                <article className={`c-event-teaser ${display}`}>
                  <div className="c-event-teaser__image">
                    <Image image={event.data.image} />
                  </div>
                  <div
                    className={`c-event-teaser__date type${event.data.type}`}
                  >
                    <span className="date">
                      {new Date(event.data.date).getDate()}
                    </span>
                    <span className="month">
                      {new Date(event.data.date).toLocaleString(
                        language || "nl",
                        { month: "short" }
                      )}
                    </span>
                  </div>
                  <div className="c-event-teaser__content">
                    {type === "All" && (
                      <div
                        className={`c-event-teaser__type type${event.data.type}`}
                      >
                        <span
                          className={`icon-${
                            event.data.type === "Event" ? "date" : "computer"
                          }`}
                        >
                          {event.data.type}
                        </span>
                      </div>
                    )}
                    {time === "All" &&
                      event.data.date >= currentDate.toISOString() && (
                        <div className={`c-event-teaser__type typeWebinar`}>
                          <span className="icon-calendar">Aankomend</span>
                        </div>
                      )}
                    <div className="c-event-teaser__title">
                      <Link to={event.url}>
                        <h3>
                          {event.data.teaser_title?.text ||
                            event.data.title.text}
                        </h3>
                      </Link>
                    </div>
                    {event.data.location && (
                      <div className="c-event-teaser__location">
                        <span className="icon-marker">
                          {event.data.location}
                        </span>
                      </div>
                    )}
                    <div
                      className="c-event-teaser__abstract"
                      dangerouslySetInnerHTML={{
                        __html: filterHtml(event.data.teaser_abstract.html),
                      }}
                    ></div>
                    <div className="c-event-teaser__link">
                      <Link className="action-go-after" to={event.url}>
                        {t("Meer lezen")}
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            ))}
            {showPodcast && display === "big" && (
              <div className="c-event-teaser__wrapper">
                <article className={`c-event-teaser big`}>
                  <div className="c-event-teaser__image">
                    <Image
                      image={{
                        url: "/podcast-teaser-2.png",
                        alt: "podcast teaser",
                      }}
                    />
                  </div>
                  <div
                    className={`c-event-teaser__date typeBig`}
                  >
                    <span className="date">
                      SEP
                    </span>
                  </div>
                  <div className="c-event-teaser__content">
                    <div className={`c-event-teaser__type typeAudio`}>
                      <span className={`icon-podcast`}>podcast</span>
                    </div>
                    <div className={`c-event-teaser__type typeWebinar`}>
                      <span className="icon-calendar">Aankomend</span>
                    </div>
                    <div className="c-event-teaser__title">
                      <Link to={data.prismicLevel2.url}>
                        <h3>
                          {data.prismicLevel2.data.title.text}
                        </h3>
                      </Link>
                    </div>
                    <div
                      className="c-event-teaser__abstract"
                      dangerouslySetInnerHTML={{
                        __html: filterHtml(data.prismicLevel2.data.abstract.html),
                      }}
                    ></div>
                    <div className="c-event-teaser__link">
                      <Link className="action-go-after" to={data.prismicLevel2.url}>
                        {t("Meer lezen")}
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            )}
          </div>
        ) : (
          <div className="o-1-col">
            <h3>
              {time === "past"
                ? t("Er zijn geen voorgaande activiteiten")
                : t("Er zijn geen opkomende activiteiten")}
            </h3>
          </div>
        )}
      </div>
    </section>
  );
};

EventTeasers.propTypes = {
  title: PropTypes.string,
  time: "coming" | "past" | "all",
  display: "small" | "big",
  type: "All" | "Events" | "Webinars",
  showPodcast: PropTypes.bool,
};
