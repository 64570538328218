import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { useTranslation } from "gatsby-plugin-react-i18next";
import slugify from "react-slugify";
import { PropTypes } from 'prop-types'
import { filterHtml } from "../utils/filterHtml";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Level3Step = ({ data, pageContext }) => {
  const { t } = useTranslation();

  if (!data) return null;
  let page = data.prismicLevel3Step;

  const { lang, type, url } = page || {};
  const title = page.data.title.text;
  const desc = page.data.meta_description;
  const alternateLanguages = page.alternate_languages || [];
  const parents = [{
    url: page.data.parent.document.data.parent.url,
    label: page.data.parent.document.data.parent.document.data.title.text
  },{
    url: page.data.parent.url,
    label: page.data.parent.document.data.title.text
  }]
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
    parents,
  };
  // determine next/prev step
  let stepIndex = null,
    nextStep = null,
    stepNumber = null;
  const nextPrevStep = () => {
    if (data.allPrismicLevel3Step?.nodes?.length) {
      for (const [index, item] of data.allPrismicLevel3Step.nodes.entries()) {
        if (item.id === pageContext.id) {
          stepIndex = index;
          break;
        }
      }
      if (stepIndex !== null) {
        stepNumber = stepIndex + 1;
        if (stepIndex < data.allPrismicLevel3Step.nodes.length - 1) {
          nextStep = data.allPrismicLevel3Step.nodes[stepIndex + 1];
        }
      }
    }
  };
  nextPrevStep();

  let tocItems = [];
  for (const value of page.data.body) {
    if (value.primary.title1?.text) {
      tocItems.push(value.primary.title1.text);
    }
  }
  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="o-wrapper">
        <h1 className="c-intro-block__title">
          <span className="number">{stepNumber}.</span> {page.data.title.text}
        </h1>
        <div
          className="o-intro-text"
          dangerouslySetInnerHTML={{ __html: filterHtml(page.data.intro.html) }}
        />
        <div className="c-divider u-mb0" />
      </div>
      <div className={`o-wrapper o-2-col -sidearea`}>
        <div>
          <SliceZone slices={page.data.body} />
          {nextStep && (
            <div className="c-next-step-link next">
              <Link to={nextStep.url}>
                <span className="next-label">{t("Volgende")}: </span>
                <span className="number">{stepNumber + 1}.</span>{" "}
                {nextStep.data.title.text}
              </Link>
            </div>
          )}
        </div>
        <div className="is-sidearea">
          <div className="c-sidearea">
            <div className="c-sticky-block">
              <div className="c-sticky-block__container">
                {page.data.show_table_of_contents && tocItems.length > 0 && (
                  <div className="toc-block">
                    <h3>{t("toc")}</h3>
                    <ul>
                      {tocItems.map((value) => {
                        return (
                          <li key={"toc-" + value}>
                            <a href={"#" + slugify(value)}>{value}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <a className="back-to-top__link" href="#content">
                  {t('Scroll back to top')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Level3Step.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default withPrismicPreview(Level3Step);

export const query = graphql`
  query ($id: String, $lang: String!, $parentId: ID!, $locale: String) {
    prismicLevel3Step(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      id
      type
      data {
        title {
          text
        }
        parent {
          url
          document {
            ... on PrismicLevel2 {
              id
              data {
                title {
                  text
                }
                parent {
                  url
                  document {
                    ... on PrismicTheme {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicDownloads {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        meta_description
        intro {
          html
        }
        show_table_of_contents
        body {
          ... on PrismicLevel3StepDataBodyImage {
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                  publicURL
                }
                thumbnails {
                  mobile {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicLevel3StepDataBodyDownload {
            id
            slice_type
            primary {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }

              label
            }
          }
          ... on PrismicLevel3StepDataBodyQuote {
            id
            primary {
              author
              image {
                alt
                url
                localFile {
                  publicURL
                }
              }
              quote {
                html
              }
            }
            slice_type
          }
          ... on PrismicLevel3StepDataBodyTextblock {
            id
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
        }
      }
      url
      alternate_languages {
        document {
          ... on PrismicLevel3Step {
            url
          }
        }
        lang
      }
      lang
    }
    allPrismicLevel3Step(
      filter: {
        data: { parent: { id: { eq: $parentId } } }
        lang: { eq: $lang }
      }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        data {
          title {
            text
          }
        }
        url
        id
        prismicId
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $locale } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
